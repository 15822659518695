<template>
    <div>
        <ts-page-title
            :title="$t('customerLoanRequest.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('customerLoanRequest.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button
                    type="info"
                    ghost
                    @click="
                        $router.push({ name: 'customer-loan-request-create' })
                    "
                    >{{ $t("addNew") }}
                </Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('customerLoanRequest.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="photo">
                    <img
                        class="tw-rounded tw-h-8"
                        v-lazy="{
                            src: photo(row.customer),
                            error:
                                row.customer.gender == 'Male'
                                    ? require('@/assets/staff-default-man.png')
                                    : require('@/assets/staff-default-women.png'),
                            loading: require('@/assets/Rolling-1s-200px.svg'),
                            preLoad: 1.3,
                            attempt: 1
                        }"
                    />
                </template>
                <template slot-scope="{ row }" slot="branch">
                    {{ row.branch ? row.branch.branch_name_en : "" }}
                </template>
                <template slot-scope="{ row }" slot="customer_code">
                    {{ row.customer ? row.customer.customer_code : "" }}
                </template>
                <template slot-scope="{ row }" slot="customer_name">
                    {{
                        row.customer
                            ? locale == "kh"
                                ? row.customer.customer_name_kh
                                : row.customer.customer_name_en
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="loan_type">
                    {{
                        row.loan_type
                            ? locale == "kh"
                                ? row.loan_type.loan_type_kh
                                : row.loan_type.loan_type_en
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="request_amount">
                    <span class="tw-font-bold text-blue">{{
                        formatCurrencyWithCode(row.request_amount, row.currency)
                    }}</span>
                </template>
                <template slot-scope="{ row }" slot="request_interest_rate">
                    {{
                        row.request_interest_rate
                            ? row.request_interest_rate + "%"
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="approve_status">
                    <span
                        class="badge bg-info"
                        v-if="row.approve_status.approve_status_id == 0"
                        >{{ row.approve_status.approve_status }}</span
                    >
                    <span
                        class="badge bg-warning"
                        v-if="row.approve_status.approve_status_id == 1"
                        >{{ row.approve_status.approve_status }}</span
                    >
                    <span
                        class="badge bg-success"
                        v-if="row.approve_status.approve_status_id == 2"
                        >{{ row.approve_status.approve_status }}</span
                    >
                    <span
                        class="badge bg-danger"
                        v-if="row.approve_status.approve_status_id == 3"
                        >{{ row.approve_status.approve_status }}</span
                    >
                </template>
                <template slot-scope="{ row }" slot="ref_loan_number">
                    {{ row.loan_disbursement ? row.loan_disbursement.loan_number : "" }}
                </template>
                <template slot-scope="{ row }" slot="app_amount">
                    <span class="tw-font-bold text-success">{{
                        formatCurrencyWithCode(row.app_amount, row.currency)
                    }}</span>
                </template>
                <template slot-scope="{ row }" slot="app_interest_rate">
                    {{
                        row.app_interest_rate ? row.app_interest_rate + "%" : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="co">
                    {{
                        row.co ? locale == "kh" ? row.co.employee_name_kh : row.co.employee_name_en : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        v-if="
                            row.approve_status.approve_status_id == 2 &&
                                !row.loan_disbursement
                        "
                        href="#"
                        class="text-indigo ml-2"
                        @click.prevent="onMakeDisbur(row)"
                        v-tooltip="$t('customerLoanRequest.loanDisbursement')"
                    >
                        <Icon type="ios-navigate" size="20" />
                    </a>
                    <a
                        v-if="row.approve_status.approve_status_id == 2"
                        href="#"
                        v-tooltip="$t('pdf')"
                        class="text-blue ml-2"
                        @click.prevent="preview(row)"
                    >
                        <i class="far fa-file-pdf tw-text-sm tw-mr-1"></i>
                    </a>
                    <a
                        v-if="row.approve_status.approve_status_id == 0"
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <a
                        v-if="row.approve_status.approve_status_id == 0"
                        href="#"
                        class="text-warning ml-2"
                        @click.prevent="onApprove(row)"
                        v-tooltip="$t('customerLoanRequest.loanApproval')"
                    >
                        <Icon type="ios-checkmark-circle" size="20" />
                    </a>
                    <Poptip
                        :title="$t('are_you_sure_to_delete')"
                        :transfer="true"
                        width="240"
                        v-if="
                            !row._deleting &&
                                row.approve_status.approve_status_id == 0
                        "
                        @on-popper-show="() => (model.deleted_reason = '')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                        <div slot="content">
                            <label class="required">{{
                                $t("journalEntry.reason")
                            }}</label>
                            <input
                                v-model.trim="model.deleted_reason"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        errors.has('deleted_reason') ||
                                        $v.model.deleted_reason.$error
                                }"
                            />
                            <span
                                class="invalid-feedback"
                                v-if="reasonErrors.length > 0"
                                >{{ reasonErrors[0] }}</span
                            >
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('deleted_reason')"
                            >
                                {{ errors.first("deleted_reason") }}
                            </div>
                            <div class="tw-mt-2 tw-text-right">
                                <a
                                    class="ivu-btn ivu-btn-primary ivu-btn-small"
                                    @click.prevent="onDelete(row)"
                                >
                                    <span>OK</span>
                                </a>
                            </div>
                        </div>
                    </Poptip>
                    <a
                        v-else-if="
                            !row._deleting &&
                                row.approve_status.approve_status_id == 0
                        "
                        class="ml-2 text-danger"
                        v-tooltip="$t('delete')"
                        :disabled="row._deleting"
                    >
                        <i
                            class="fas fa-circle-notch fa-spin"
                            v-if="row._deleting"
                        ></i>
                        <Icon type="ios-trash" size="20" v-else />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("branchName")
                }}</label>
                <ts-branch-filter @filter="value => (branch_id = value)" />
            </div>
        </Drawer>
        <contract-pdf-preview
            v-model="contract_pdf_view"
            :request-id="request_id"
            @cancel="value => (contract_pdf_view = value)"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { debounce } from "lodash";
import { Errors } from "form-backend-validation";
import ContractPdfPreview from "./components/contract-pdf-preview.vue";
import { required } from "vuelidate/lib/validators";

export default {
    name: "customerIndex",
    components: {
        ContractPdfPreview
    },
    data() {
        return {
            loading: false,
            waiting: false,
            contract_pdf_view: false,
            errors: new Errors(),
            width: "width: 300px",
            visible: false,
            branch_id: [],
            customer_id_lists: [],
            exporting: false,
            request_id: null,
            model: {
                deleted_reason: ""
            }
        };
    },
    validations: {
        model: {
            deleted_reason: { required }
        },
        form: ["model.deleted_reason"]
    },
    computed: {
        ...mapState("creditOperation/customerLoanRequest", [
            "resources",
            "pagination"
        ]),
        ...mapGetters(["formatCurrencyWithCode"]),
        search: {
            get() {
                return this.$store.state.creditOperation.customerLoanRequest
                    .search;
            },
            set(newValue) {
                this.$store.commit(
                    "creditOperation/customerLoanRequest/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "creditOperation/customerLoanRequest/RESET_CURRENT_PAGE"
                );
            }
        },
        reasonErrors() {
            const errors = [];
            if (!this.$v.model.deleted_reason.$error) return errors;
            !this.$v.model.deleted_reason.required && errors.push("Required");
            return errors;
        },
        columns() {
            return [
                {
                    title: this.$t("customerLoanRequest.branch"),
                    slot: "branch",
                    minWidth: 100
                },
                {
                    title: this.$t("customerLoanRequest.requestDate"),
                    key: "requested_date",
                    align: "center",
                    minWidth: 120
                },
                {
                    title: this.$t("customerLoanRequest.requestNumber"),
                    key: "request_number",
                    align: "center",
                    minWidth: 120
                },
                {
                    title: this.$t("customerLoanRequest.groupId"),
                    key: "group_id",
                    align: "center",
                    minWidth: 120
                },
                {
                    title: this.$t("customerLoanRequest.photo"),
                    slot: "photo",
                    minWidth: 80
                },
                {
                    title: this.$t("customerLoanRequest.customerCode"),
                    slot: "customer_code",
                    minWidth: 120,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("customerLoanRequest.customerName"),
                    slot: "customer_name",
                    minWidth: 150,
                    sortable: true
                },
                {
                    title: this.$t("customerLoanRequest.loanType"),
                    slot: "loan_type",
                    minWidth: 150
                },
                {
                    title: this.$t("customerLoanRequest.requestAmount"),
                    slot: "request_amount",
                    minWidth: 150,
                    align: "right"
                },
                {
                    title: this.$t("customerLoanRequest.requestInterestRate"),
                    slot: "request_interest_rate",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: this.$t("customerLoanRequest.approveStatus"),
                    slot: "approve_status",
                    minWidth: 150,
                    align: "center",
                    filters: [
                        {
                            label: "Pending",
                            value: 0
                        },
                        {
                            label: "Partially Approved",
                            value: 1
                        },
                        {
                            label: "Approved",
                            value: 2
                        },
                        {
                            label: "Rejected",
                            value: 3
                        }
                    ],
                    filterMultiple: true,
                    filterRemote(value) {
                        this.fetchData({
                            filter: {
                                approve_status: value
                            }
                        });
                    }
                },
                {
                    title: this.$t("customerLoanRequest.co"),
                    slot: "co",
                    minWidth: 200
                },
                {
                    title: this.$t("customerLoanRequest.refLoanNumber"),
                    slot: "ref_loan_number",
                    align: "center",
                    minWidth: 150
                },
                {
                    title: this.$t("customerLoanRequest.approveAmount"),
                    slot: "app_amount",
                    minWidth: 150,
                    align: "right"
                },
                {
                    title: this.$t("customerLoanRequest.appInterestRate"),
                    slot: "app_interest_rate",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    width: 120
                }
            ];
        },
        locale() {
            return this.$root.$i18n.locale;
        }
    },
    methods: {
        photo(record) {
            if (record.photo) {
                return record.photo;
            }

            return record.gender == "Male"
                ? require("@/assets/staff-default-man.png")
                : require("@/assets/staff-default-women.png");
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch(
                    "creditOperation/customerLoanRequest/fetch",
                    attributes
                )
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onEdit(record) {
            this.$store.commit(
                "creditOperation/customerLoanRequest/SET_EDIT_DATA",
                record
            );
            this.$router.push({
                name: "customer-loan-request-edit",
                params: { id: record.request_id }
            });
        },
        onApprove(record) {
            this.$store.commit(
                "creditOperation/customerLoanRequest/SET_EDIT_DATA",
                record
            );
            this.$router.push({
                name: "customer-loan-request-approve",
                params: { id: record.request_id }
            });
        },
        onMakeDisbur(record) {
            this.$store.commit(
                "creditOperation/customerLoanRequest/SET_EDIT_DATA",
                record
            );
            this.$router.push({
                name: "customer-loan-request-make-disbursement",
                params: { id: record.request_id }
            });
        },
        async onDelete(record) {
            this.$v.form.$touch();
            if (this.$v.form.$invalid) return;

            record._deleting = true;
            this.$store
                .dispatch("creditOperation/customerLoanRequest/destroy", {
                    id: record.request_id,
                    data: this.model
                })
                .then(response => {
                    this.fetchData();
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    record._deleting = false;
                    this.model.deleted_reason = "";
                });
        },
        preview(record) {
            this.contract_pdf_view = true;
            this.request_id = record.request_id;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "CUSTOMER LOAN REQUEST",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search, branch_id: this.branch_id });
        }, 500),
        branch_id: debounce(function(value) {
            this.fetchData({ branch_id: value });
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("creditOperation/customerLoanRequest/RESET_STATE");
        next();
    }
};
</script>
<style scoped>
.text-nowrap {
    white-space: nowrap;
}
</style>
